import axios from 'axios';
import { SERVICE_LAYER_URL } from '../../config';

class RestServiceImpl {
  
  async unsubscribe(email) {
    return await this._subscribingRequest('/communication/email/unsubscribe', email)
  }

  async resubscribe(email) {
    return await this._subscribingRequest('/communication/email/resubscribe', email)
  }

  async _subscribingRequest(url, email) {
    return await this._restCall({
      method: 'POST',
      path: url,
      data: {
        email
      }
    });
  }

  async _restCall(params, shouldRetry) {
    let headers = {};
    if (params.method === 'POST') {
      headers['Content-Type'] = (params.dataFormat === 'form') ? 'application/x-www-form-urlencoded' : 'application/json';
    }

    let options = {
      method: params.method,
      headers: headers,
      url: SERVICE_LAYER_URL + params.path,
      data: params.data
    };

    let errorMessage = null;
    try {
      let response = await axios(options);
      if (response.status < 299) {
        return response;
      }
      errorMessage = response.status.toString();
    }
    catch (error) {
      if (error.response && error.response.status) {
        errorMessage = error.response.status.toString();
      }
      else {
        errorMessage = error.message;
      }
      console.log(errorMessage);
    }
  }
}

const RestService = new RestServiceImpl();

export {RestService};