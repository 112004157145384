import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { getLanguageDirection, useLanguage } from './language-context';
import { GlobalStyle } from './layout';
import { Subsection } from './section';
import feedbackNowLogo from '../images/feedbacknow-logo-by-forrester-white.svg';

import redSmileyIcon from '../images/red-smiley.svg';
import greenSmileyIcon from '../images/green-smiley.svg';
import { media } from '../styles/media-query-template';

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Proxima Nova';
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  background: black;
  width: 100%;
  height: 50px;
`

const CenteredBox = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  align-items: center;
  border: solid 2px #000;
  background: white;
  width: calc(100% - 30px);

  ${media.large`
    width: 570px;
    margin: 0;
  `}
`

const Content = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0 15px;

  ${media.large`
    width: 470px;
    margin: 0;
    padding: 0;
  `}
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
`

const Logo = styled.img`
  width: 300px;
`

const SmileyIcon = styled.img`
  margin-left: 10px;
  width: 30px;
`

export const SubscribingPageLayout = useLanguage(({ lang, children, title, smiley }) => {

  const [smileyIcon, setSmileyIcon] = useState(null);

  useEffect(() => {
    if (smiley == 'red') {
      setSmileyIcon(redSmileyIcon);
    }
    else if (smiley === 'green') {
      setSmileyIcon(greenSmileyIcon);
    }
  }, []);
  
  return (
    <>
      <Helmet>
        <html lang={lang} dir={getLanguageDirection(lang)} />
        <title>Feedbacknow</title>
      </Helmet>
      <GlobalStyle lang={lang} dir={getLanguageDirection(lang)} />
      <Wrapper>
        <CenteredBox>
          <Header>
            <Logo src={feedbackNowLogo} />
          </Header>
          <Content>
            <Title>
              <span>{title}</span>
              {smileyIcon && <SmileyIcon src={smileyIcon} />}
            </Title>
            <br />
            {children}
          </Content>
        </CenteredBox>
      </Wrapper>
    </>
  )
});