import React, { useEffect, useState } from 'react'

import { passLanguage, useLanguage } from '../components/language-context'
import { SubscribingPageLayout } from '../components/subscribing-page-layout'
import { RestService } from '../services/rest-service';


const ResubscribePageBase = useLanguage(({location, lang, msg}) => {

  useEffect(() => {
    const parameters = new URLSearchParams(location.search);
    const emailParameter = parameters.get("email");
    
    if (emailParameter) {
      RestService.resubscribe(emailParameter);
    }
  }, []);

  return (
    <SubscribingPageLayout title={msg('resubscribe-title')} smiley={'green'}>
       <div>
          {msg('resubscribe-p1-content')}
        </div>
        <div>
          {msg('resubscribe-p2-content')}
        </div>
    </SubscribingPageLayout>
  )
});

const ResubscribePage = passLanguage(ResubscribePageBase);
export default ResubscribePage;